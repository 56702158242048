@import './../variables/breakpoints.scss';

@mixin on-small-mobile {
  @media screen and (max-width: $screen-xs) {
    @content;
  }
}

@mixin on-mobile {
  @media screen and (max-width: $screen-s) {
    @content;
  }
}

@mixin on-tablet {
  @media screen and (min-width: ($screen-s + 1px)) and (max-width: $screen-l) {
    @content;
  }
}

@mixin on-desktop {
  @media screen and (min-width: ($screen-l + 1px)) {
    @content;
  }
}

@mixin on-big-tablet {
  @media screen and (min-width: ($screen-l + 1px)) and (max-width: $screen-xl) {
    @content;
  }
}

@mixin on-big-screen {
  @media screen and (min-width: ($screen-xl + 1px)) {
    @content;
  }
}

@mixin over-mobile {
  @media screen and (min-width: ($screen-s + 1px)) {
    @content;
  }
}

@mixin under-desktop {
  @media screen and (max-width: ($screen-l)) {
    @content;
  }
}
