@import 'styles/variables/colors.scss';
@import 'styles/mixins/media-queries.scss';
@import 'styles/mixins/typo.scss';

.footer {
  @import './FooterBlock/FooterBlock.scss';
  @import './FooterMenu/FooterMenu.scss';
  @import './FooterNewsletter/FooterNewsletter.scss';
  @import './FooterSocial/FooterSocial.scss';

  padding-top: 4rem;
  border-top: 0.1rem solid $color-grey-100;

  &__columns {
    display: grid;
    gap: 0.75rem;

    @include on-tablet {
      grid-template-columns: repeat(4, 1fr);
      gap: 3rem;
    }

    @include on-desktop {
      grid-template-columns: 1fr 1fr 1fr 1fr 1.35fr;
      gap: 5rem;
    }
  }

  &__column {
    position: relative;

    &--social {
      display: grid;
      gap: 3rem;

      @include on-tablet {
        order: 5;
        grid-column: 1 / span 4;
        grid-template-columns: repeat(2, 1fr);
        gap: 3rem;
      }

      @include on-desktop {
        order: 5;
        gap: 5rem;
      }
    }
  }

  &__copyright {
    text-align: center;
    padding-top: 6rem;
    padding-bottom: 2rem;
    margin-top: 3rem;
    border-top: 0.1rem solid $color-grey-100;
  }
}
