@import 'styles/variables/colors';
@import 'styles/mixins/media-queries';

.BackToTop {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 5rem;
  width: 5rem;
  font-size: 2.5rem;
  border: 0.1rem solid $color-grey-999;
  background-color: $color-grey-999;
  color: $color-grey-000;
  bottom: 7rem;
  right: 1.6rem;
  opacity: 0;
  pointer-events: none;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  cursor: pointer;
  z-index: 4;

  span {
    font-size: 0.4em;
  }

  @include on-desktop {
    bottom: 2.5rem;
    right: 2.5rem;
  }

  &[data-visible='true'] {
    opacity: 1;
    pointer-events: auto;

    &:hover {
      background-color: $color-grey-000;
      color: $color-grey-999;
    }
  }
}
