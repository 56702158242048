.header__menu {
  grid-area: MENU;
  display: flex;
  align-items: center;
  position: relative;
  @include hide-scrollbar;

  @import './partials/HeaderMenuItem/HeaderMenuItem';
  @import './partials/HeaderMenuDrawer/HeaderMenuDrawer';
  @import './partials/HeaderMenuLayer/HeaderMenuLayer';
  @import './partials/HeaderMenuChild/HeaderMenuChild';

  a {
    text-decoration: none;
  }

  &__burger {
    font-size: 3rem;
    width: 1em;
    display: flex;
  }

  &__overlay {
    position: fixed;
    z-index: 1;
    inset: 0;
    background-color: $color-grey-999;
    transition: opacity 0.2s ease;
    cursor: pointer;
  }

  &__navigation {
    position: fixed;
    z-index: 1;
    inset: 0;
    width: min(90vw, 52rem);
    height: 100vh;
    overflow: hidden;
    background-color: $color-grey-000;
    transition: transform 0.2s ease;
    display: flex;
    flex-direction: column;

    &__close {
      padding-right: 1rem;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 5rem;
      font-size: 2rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-shrink: 0;
      border-bottom: 0.1rem solid $color-grey-100;

      button {
        width: 3rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &__items {
      flex-grow: 1;
      overflow: auto;
      display: flex;
      flex-direction: column;
    }
  }

  @include under-desktop {
    &__overlay {
      &[data-visible='false'] {
        opacity: 0;
        pointer-events: none;
      }

      &[data-visible='true'] {
        opacity: 0.3;
        pointer-events: all;
      }
    }

    &__navigation {
      &[data-visible='false'] {
        transform: translateX(-100%);
      }

      &[data-visible='true'] {
        transform: translateX(0);
      }
    }
  }

  @include on-desktop {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: -0.1rem;
      left: -50vw;
      right: -50vw;
      border: 0.1rem solid $color-grey-100;
      z-index: 0;
    }

    &__burger,
    &__overlay {
      display: none;
    }

    &__navigation {
      width: 100%;
      height: auto;
      display: block;
      position: static;
      overflow: initial;

      &__close {
        display: none;
      }

      &__items {
        flex-direction: row;
        align-items: center;
        height: 100%;
      }
    }
  }

  @media screen and (min-width: 1250px) and (max-width: $screen-xl) {
    margin-inline: calc((100vw - 118rem) / -2);

    &__navigation__items {
      gap: 1.2rem;
    }
  }

  @include on-big-screen {
    margin-inline: 0;
    padding-inline: 0;

    &__navigation__items {
      gap: 1.6rem;
    }
  }
}
