@import 'styles/mixins/media-queries';

.Markdown {
  font-size: 1.6rem;

  &__bold {
    font-weight: bold;
  }

  &__italic {
    font-style: italic;
  }

  &__underline {
    text-decoration: underline;
  }

  h2 {
    font-size: 1.8em;
    font-weight: bold;

    &.heading-1 {
      font-size: 2em;
    }
  }

  h3 {
    font-size: 1.6em;
    font-weight: bold;
  }

  h4 {
    font-size: 1.4em;
    font-weight: bold;
  }

  h5 {
    font-size: 1.2em;
    font-weight: bold;
  }

  h6 {
    font-size: 1em;
    font-weight: bold;
  }

  p {
    min-height: 1em;
    line-height: 1.44em;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  a {
    text-decoration: underline;
    font-weight: bold;
  }

  table {
    border-spacing: 2rem;

    tr {
      vertical-align: top;
      td {
        vertical-align: top;
      }
    }
  }

  @include on-mobile {
    table,
    thead,
    tbody {
      display: block;

      tr {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;
      }
    }
  }
}
