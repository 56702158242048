@import 'styles/variables/colors';
@import 'styles/mixins/typo';
@import 'styles/mixins/picto';
@import 'styles/mixins/media-queries';

.Alert {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  z-index: 2;

  &__item {
    position: relative;
    color: $color-grey-000;
    font-size: 1.6rem;
    text-align: center;
    padding: 1.6rem 3.2rem;
    transition: opacity 0.25s ease, transform 0.25s ease;

    &[data-type='error'] {
      background: $color-danger-500;
    }
    &[data-type='success'] {
      background: $color-success-500;
    }
    &[data-type='info'] {
      background: $color-info-500;
    }
    &[data-type='warning'] {
      background: $color-warning-500;
    }

    &__close {
      position: absolute;
      top: 0.6rem;
      right: 0.6rem;
      padding: 1rem;
      font-size: 1.4rem;
      opacity: 0.7;

      &:hover,
      &:focus {
        opacity: 1;
      }
    }
  }
}
