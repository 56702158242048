@import 'styles/variables/colors.scss';
@import 'styles/mixins/media-queries.scss';

.reinsurance {
  width: 100%;
  border-top: 0.1rem solid $color-grey-100;
  padding-top: 4rem;
  padding-bottom: 4rem;

  &__items {
    justify-content: center;
    display: flex;
    gap: 1.6rem;
  }

  &__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
    transition: opacity 0.2s ease;

    svg {
      font-size: 2.7rem;
      margin-bottom: 1.6rem;
    }

    &:hover {
      opacity: 0.6;
    }
  }

  &__text {
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 1rem;
    line-height: 2.4em;
  }
}

@include on-desktop {
  .reinsurance {
    &__items {
      gap: 4.5rem;
    }

    &__item {
      width: 16rem;
      font-size: 1.4rem;
      line-height: 2rem;

      svg {
        font-size: 4rem;
        margin-bottom: 3.5rem;
      }
    }

    &__text {
      font-size: 1.4rem;
      line-height: 1.8em;
      max-width: 106rem;
    }
  }
}
