&__logo {
  grid-area: LOGO;
  margin: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;

  &__image {
    width: 10rem;
    margin-bottom: 0.5em;
    aspect-ratio: 100/35;
    object-fit: contain;
  }

  &__baseline {
    @include housky;
    display: none;
    font-size: 3.5rem;
    padding-top: 0.6rem;
    padding-left: 3rem;
    margin-left: 1.5rem;
    text-indent: -1.5rem;
    border-left: 0.25rem solid $color-grey-999;
    line-height: 0.45;
  }

  @include on-desktop {
    width: 28rem;

    &__image {
      width: 12.5rem;
    }

    &__baseline {
      display: block;
    }
  }

  @include on-big-screen {
    width: 32rem;

    &__image {
      width: 15.5rem;
    }
  }
}
