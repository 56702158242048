&__block {
  position: relative;

  &__title {
    font-weight: bold;
    margin-bottom: 2rem;
  }

  &__toggle {
    display: none;
    pointer-events: none;
    user-select: none;
    cursor: pointer;

    svg {
      width: 1em;
      height: 1em;
    }
  }

  @include on-mobile {
    &__toggle {
      pointer-events: all;
      display: block;
      position: absolute;
      width: 100%;
      top: 0;
      height: 2.5rem;
      text-align: right;
      font-size: 0.7rem;
    }

    &__toggle + &__content:not(.is-open) {
      max-height: 0;
      overflow: hidden;

      * {
        visibility: hidden;
        display: none;
      }
    }
  }
}
