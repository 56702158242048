&__pictos {
  grid-area: PICTOS;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 10rem;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    text-decoration: none;
    position: relative;

    &__icon {
      font-size: 2.2rem;
    }

    &__label {
      display: none;
    }

    &__badge {
      position: absolute;
      top: 0;
      left: calc(50% + 0.05rem);
      background-color: $color-grey-999;
      color: $color-grey-000;
      padding: 0.25em 0.4em;
      font-size: 1.1rem;
      line-height: 1;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      padding-inline: 0.5em;
      border-radius: 1rem;
    }
  }

  @include on-desktop {
    width: 25rem;
    gap: 1rem;

    &__item {
      width: 100%;

      &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0.3rem;
        background-color: $color-primary-01-200;
        left: 0;
        bottom: -0.75rem;
        transition: width 0.2s ease;
      }

      &:hover {
        &::after {
          width: 3rem;
        }
      }

      &__label {
        text-align: center;
        font-size: 1.2rem;
        font-weight: bold;
        line-height: 0.9;
        word-break: keep-all;
        display: block;
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
  }
}
