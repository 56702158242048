#global-container {
  min-height: 100vh;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: stretch;

  @mixin grid {
    position: relative;
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    column-gap: 2rem;
  }

  #header-container {
    @include grid;
  }

  #main-container {
    @include grid;
    flex-grow: 1;
  }

  #footer-container {
    @include grid;
  }
}
