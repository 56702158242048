@mixin picto($icon, $position: 'after') {
  position: relative;

  @if $icon {
    $iconPath: url('/icons/#{$icon}.svg');

    &::#{$position} {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 1em;
      height: 1em;

      @supports (mask: $iconPath) {
        mask: $iconPath;
        background-color: currentColor;
      }

      @supports not (mask: $iconPath) {
        background-image: $iconPath;
        background-position: center;
        background-size: contain;
      }

      @if $position == 'after' {
        margin-left: 1ch;
      }
      @if $position == 'before' {
        margin-right: 1ch;
      }
    }
  }
}
