@import 'styles/variables/colors.scss';
@import 'styles/mixins/media-queries.scss';
@import 'styles/mixins/typo.scss';
@import 'styles/mixins/picto.scss';

.header {
  display: grid;
  position: relative;
  z-index: 1;

  @include on-mobile {
    padding-block: 0.75rem;
    row-gap: 0.75rem;
    column-gap: 3rem;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: auto 1fr auto;
    grid-template-areas:
      'MENU LOGO PICTOS'
      'SEARCH SEARCH SEARCH';
  }

  @include on-tablet {
    padding-block: 1.25rem;
    column-gap: 8rem;
    grid-template-rows: 1fr;
    grid-template-columns: auto auto 1fr auto;
    grid-template-areas: 'MENU LOGO SEARCH PICTOS';
  }

  @include on-desktop {
    column-gap: 6rem;
    grid-template-rows: 9rem 5.5rem;
    grid-template-columns: auto 1fr auto;
    grid-template-areas:
      'LOGO SEARCH PICTOS'
      'MENU MENU MENU';
  }

  @import './HeaderLogo/HeaderLogo';
  @import './HeaderSearch/HeaderSearch';
  @import './HeaderPictos/HeaderPictos';
  @import './HeaderMenu/HeaderMenu';
}
