.skiplinks {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  text-align: center;

  a {
    text-decoration: none;
    background-color: $color-grey-000;
    position: absolute;
    height: 0.1rem;
    width: 0.1rem;
    left: -100rem;
    top: -100rem;
    margin-inline: auto;

    &:focus {
      display: inline-block;
      margin-top: 0.8rem;
      padding: 0.4rem 0.8rem;
      overflow: visible;
      position: static;
      width: auto;
      height: auto;
      box-shadow: 0 0 0.8rem 0.3rem rgba($color-grey-999, 0.5);

      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 100vh;
        background-color: rgba($color-grey-999, 0.25);
        z-index: -1;
      }
    }
  }
}
