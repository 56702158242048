@import 'styles/mixins/media-queries';

.container {
  position: relative;
  width: 100%;
  max-width: 118rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin: auto;

  @include on-big-screen {
    max-width: 144rem;
    padding-left: 0;
    padding-right: 0;
  }
}
