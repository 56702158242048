button {
  cursor: pointer;
}

* {
  box-sizing: border-box;
  word-break: break-word;
}

a {
  color: inherit;
}

input,
button,
select,
textarea {
  color: inherit;
  border-radius: 0;
  appearance: none;
  background: none;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

img {
  display: block;
  width: 100%;
}
