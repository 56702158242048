&__item {
  border-bottom: 0.1rem solid $color-grey-100;
  padding: 0.5rem;
  z-index: 1;

  &--ss-pink {
    background-color: $color-secondary-ss-pink;
    color: $color-grey-999;
  }
  &--ss-orange {
    background-color: $color-secondary-ss-orange;
    color: $color-grey-999;
  }
  &--ss-blue {
    background-color: $color-secondary-ss-blue;
    color: $color-grey-999;
  }
  &--ss-bluedark {
    background-color: $color-secondary-ss-bluedark;
    color: $color-grey-999;
  }
  &--aw-pink {
    background-color: $color-secondary-aw-pink;
    color: $color-grey-000;
  }
  &--aw-green {
    background-color: $color-secondary-aw-green;
    color: $color-grey-000;
  }
  &--aw-greendark {
    background-color: $color-secondary-aw-greendark;
    color: $color-grey-000;
  }
  &--aw-bluedark {
    background-color: $color-secondary-aw-bluedark;
    color: $color-grey-000;
  }

  &__link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    width: 100%;
    font-size: 1.8rem;
    text-align: left;
    position: relative;
    word-break: keep-all;
    white-space: nowrap;
    padding: 1rem 1rem 1rem 1.5rem;

    &:is(button) {
      @include picto('arrow--down', 'after');

      &::after {
        font-size: 0.5em;
        transition: transform 0.2s ease;
      }

      &[data-active='true']::after {
        transform: rotate(-180deg);
      }
    }
  }

  @include on-desktop {
    border-bottom: none;

    &__link {
      width: auto;
      font-size: 1.6rem;
      padding: 1rem;
      gap: 1rem;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 1rem;
        bottom: -0.2rem;
        width: 0;
        height: 0.3rem;
        background-color: currentColor;
        transition: width 0.2s linear;
      }

      &:hover::before,
      &:focus::before {
        width: 5rem;
      }
    }

    &:first-of-type {
      margin-right: auto;
      border-top: none;

      .header__menu__item__link {
        padding-left: calc(2em + 1rem);
        @include picto('burger', 'after');

        &[data-active='true']::after {
          transform: none;
        }

        &::after {
          font-size: 2em;
          position: absolute;
          margin-left: 0;
          left: 0;
          top: 0.2em;
        }

        &::before {
          left: 0;
        }
      }
    }
  }

  @media screen and (min-width: 1250px) {
    &__link {
      padding: 1rem 1.5rem;

      &::before {
        left: 1.5rem;
      }
    }
  }

  @include on-big-screen {
    &__link {
      padding: 1rem 2rem;

      &::before {
        left: 2rem;
      }
    }
  }
}
