&__search {
  grid-area: SEARCH;
  display: flex;
  justify-items: center;
  align-items: center;

  &__input {
    appearance: none;
    height: 4rem;
    line-height: 4rem;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    border: 0.1rem solid $color-grey-800;

    &::placeholder {
      font-size: 0.85em;
      color: $color-grey-500;
    }
  }

  &__button {
    height: 4rem;
    width: 4rem;
    display: flex;
    background-color: $color-grey-999;
    border: 0.1rem solid $color-grey-999;
    color: $color-grey-000;
    cursor: pointer;

    &:hover {
      background-color: $color-grey-000;
      color: $color-grey-999;
    }

    &__picto {
      margin: auto;

      &[data-icon='search'] {
        font-size: 2.25rem;
      }

      &[data-icon='cross'] {
        font-size: 1.5rem;
      }
    }
  }
}
