@import 'styles/variables/colors.scss';
@import 'styles/mixins/media-queries.scss';
@import 'styles/mixins/typo.scss';
@import 'styles/mixins/picto.scss';

.Breadcrumbs {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 1ch;

    &__item {
      font-size: 1.8rem;
      display: none;
      align-items: center;
      @include picto('arrow--long', 'before');

      &:nth-last-child(2) {
        display: flex;

        &::before {
          font-size: 2rem;
          transform: scaleX(-1);
        }
      }
    }
  }

  @include over-mobile {
    padding-top: 2rem;
    padding-bottom: 2rem;

    &__list {
      &__item {
        display: flex;
        font-size: 1.4rem;

        &--current {
          font-weight: bold;
        }

        &:first-of-type::before {
          display: none;
        }

        &:not(:first-of-type)::before {
          transform: none;
          font-size: 1.1rem;
          margin-top: 0.25ch;
        }
      }
    }
  }
}
