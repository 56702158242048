&__drawer {
  margin-inline: -0.5rem;
  background-color: $color-grey-000;
  overflow: hidden;
  transition: height 0.25s ease;
  position: relative;

  &__overlay {
    display: none;
  }

  &[data-visible='false'] {
    height: 0;
  }

  &[data-visible='true'] {
    height: var(--drawer--height, auto);
  }

  @include on-desktop {
    margin: 0;
    position: absolute;
    top: 100%;
    width: 43rem;
    border: 0.1rem solid $color-grey-100;
    border-top: none;
    z-index: 1;

    &__overlay {
      display: block;
      position: absolute;
      top: calc(100% + 0.1rem);
      left: -100vw;
      right: -100vw;
      height: 9999vh;
      background-color: $color-grey-999;
      opacity: 0;
      z-index: 0;
      transition: opacity 0.25s ease;
      pointer-events: none;
    }

    &[data-visible='true'] {
      left: var(--drawer--left, 0);
      margin-bottom: -0.5rem;
      margin-bottom: none;

      & + .header__menu__drawer__overlay {
        opacity: 0.3;
        pointer-events: all;
        cursor: pointer;
      }
    }
  }
}
