&__newsletter {
  position: relative;
  margin-bottom: 3rem;

  label {
    display: block;
    margin-bottom: 1.6rem;
    font-weight: 100;
  }

  .input-group {
    display: flex;
    position: relative;

    input {
      flex-grow: 1;
      height: 4rem;
      padding: 1rem;
      padding-right: 6.5rem;
      line-height: 4rem;
      font-size: 1.4rem;
      font-style: italic;
      appearance: none;
      border: 0.1rem solid $color-grey-100;
      background-color: $color-grey-100;

      &:focus {
        background-color: $color-grey-000;
        border-color: $color-grey-200;

        & + button {
          background-color: $color-grey-000;

          &:hover,
          &:focus {
            background-color: $color-grey-999;
            color: $color-grey-000;
          }
        }
      }

      &.has-error {
        border-color: $color-danger-500;
        border-right: none;

        & + button {
          border-color: $color-danger-500;
          border-left: none;
        }
      }
    }

    button {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      border: 0.1rem solid $color-grey-100;
      font-size: 0;
      display: flex;
      width: 5.5rem;
      background-color: $color-grey-100;

      svg {
        height: 1.75rem;
        width: 1.75rem;
        margin: auto;
      }

      &:hover,
      &:focus {
        background-color: $color-grey-999;
        color: $color-grey-000;
      }
    }
  }
}
