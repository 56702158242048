&__social {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  align-items: center;

  &__baseline {
    @include housky;
    font-size: 6rem;
    width: 100%;

    @include on-tablet {
      width: auto;
    }
  }

  &__pictos {
    display: inline-flex;
    gap: 2rem;

    a {
      display: flex;
      font-size: 3rem;
    }
  }
}
