&__menu {
  &__item {
    padding-block: 0.75rem;

    a {
      font-size: 1.4rem;
      color: $color-grey-500;
      text-decoration: none;
      transition: color 0.2s linear;

      &:hover,
      &:focus {
        color: $color-grey-999;
      }
    }
  }
}
