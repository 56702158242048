html {
  font-size: 10px;
  font-size: 62.5%;
  scroll-behavior: smooth;
  background-color: $color-grey-000;

  *:focus {
    outline: none;

    // Display outline boxes on non-touch devices
    @media (hover: hover) and (pointer: fine) {
      @include set-shadow-focus();
    }
  }

  // Augment font-size and contrast for A11Y
  @media (prefers-contrast: more) {
    font-size: 11px;
    font-size: 68.75%;
    filter: contrast(1.5) grayscale(0.5);
  }

  body {
    font-size: 1.6rem;
    color: $color-grey-999;
    font-family: Lato, Arial, sans-serif;

    &.is-blocked {
      overflow: hidden;
    }
  }

  hr {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    border: none;
    border-bottom: 0.1rem solid $color-grey-100;
  }

  hr {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    border: none;
    border-bottom: 0.1rem solid $color-grey-100;
  }

  mark {
    background-color: $color-primary-01-200;
  }

  .sr-only {
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
}
