&__layer {
  position: fixed;
  inset: 0;
  width: 100%;
  background-color: $color-grey-000;
  overflow: hidden;
  z-index: 2;
  transition: margin-left 0.25s ease;

  &[data-visible='false'] {
    pointer-events: none;
    margin-left: 100%;
  }

  &[data-visible='true'] {
    pointer-events: all;
    margin-left: 0;
  }

  &__close {
    padding: 0.5rem;
    border-top: 0.1rem solid $color-grey-100;

    &__button {
      display: flex;
      width: 100%;
      padding: 0.5rem 1rem;
      gap: 1.5rem;
      align-items: center;

      &__icon {
        flex-shrink: 0;
        width: 3em;
        height: 3em;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.5em;
        border: 0.1rem solid currentColor;
        border-radius: 100%;
        transition: color 0.2s ease, background-color 0.2s ease;
      }

      &:hover .header__menu__layer__close__button__icon,
      &:focus .header__menu__layer__close__button__icon {
        color: $color-grey-000;
        background-color: $color-grey-900;
      }
    }
  }

  &__items {
    overflow: auto;
    max-height: calc(100% - 4.5rem);
  }

  &__item {
    padding: 0.5rem;
    font-size: 1.4rem;
    border-top: 0.1rem solid $color-grey-100;

    &--main {
      font-weight: bold;
    }

    &--list {
      display: flex;
      align-items: center;
      @include picto('arrow--right', 'after');

      &::after {
        flex-shrink: 0;
        font-size: 0.5em;
        margin-left: auto;
      }
    }

    &__link {
      display: flex;
      align-items: center;
      gap: 1.6rem;
      width: 100%;
      text-align: left;
      padding: 0.5rem 1.5rem;
    }
  }

  @include on-desktop {
    position: absolute;
    width: 100%;

    &__items {
      overflow: auto;
      max-height: calc(100vh - 20rem);
    }
  }
}
