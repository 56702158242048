.header__menu__child {
  padding: 0.5rem;
  font-size: 1.4rem;
  line-height: 1.2em;
  border-top: 0.1rem solid $color-grey-100;

  &__inner {
    position: relative;
    display: flex;
    align-items: center;
    gap: 1.6rem;
    width: 100%;
    text-align: left;
    padding: 0.75rem 0.75rem 0.75rem 1.5rem;

    &[data-type='main'] {
      font-weight: bold;
      text-transform: uppercase;
    }

    &[data-type='list'] {
      @include picto('arrow--right', 'after');

      &::after {
        flex-shrink: 0;
        font-size: 0.5em;
        margin-left: auto;
      }
    }

    &__image {
      width: 12rem;
      aspect-ratio: 16/9;
      object-fit: cover;
    }

    &__name {
      span {
        display: block;

        & + span {
          font-size: 1.5rem;
          font-weight: bold;
        }
      }
    }
  }

  @include on-desktop {
    &__inner {
      padding-left: 0.75rem;

      &::before {
        content: '';
        position: absolute;
        top: -0.5rem;
        left: -0.5rem;
        bottom: -0.5rem;
        width: 0;
        background-color: $color-primary-01-200;
        z-index: -1;
        transition: width 0.2s ease;
      }

      &:hover::before,
      &:focus::before {
        width: calc(100% + 2rem);
      }
    }
  }
}
